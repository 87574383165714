'use client';

import { m } from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// layouts
import CompactLayout from 'src/layouts/compact';
// routes
import { RouterLink } from 'src/routes/components';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
// assets
import { ForbiddenIllustration, PageNotFoundIllustration } from 'src/assets/illustrations';

// ----------------------------------------------------------------------

export default function NotFoundView() {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
          Uh Oh…Permissions Lost
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary', textAlign: 'left !important', ml: "40px" }}>
            The page you&apos;re trying access is restricted.
            <br />
            Don&apos;t worry. We want to get this fixed for you. 
            <br />
            <br/>
            <h3>Potential Reasons for this:</h3>
            1. You aren&apos;t a member of Storefront Stalker Pro…yet 😉
            <br />
            2. Your bank messed up and your payment may have failed or may be overdue.
            <br />
            3. You&apos;re trying to access a page we haven&apos;t built yet.
            <br />
            4. You&apos;re trying to access a page that doesn&apos;t exist in this dimension.
            <br/>
            <br/>
            <h5>If this is an error we want to help, please contact us on discord or email us at storefrontstalkerpro@gmail.com</h5>
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button component={RouterLink} href="/" size="large" variant="contained">
          Go to Home
        </Button>
      </MotionContainer>
    </CompactLayout>
  );
}
